import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import MicrositePreviewGrid from '../components/microsite-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
// import MicrositeLayout from '../containers/micrositeLayout'

export const query = graphql`
  query MicrositesPageQuery {
    production: allSanityMicrosite(
      filter: {
        slug: { current: { ne: null } }
        _id: { regex: "/^(?!(drafts)).+/" }
        workflowStatus: { in: ["Production"] }
      }
      sort: { fields: [slug___current], order: ASC }
    ) {
      edges {
        node {
          id
          mainBannerImage {
            asset {
              _id
              gatsbyImageData(layout: FIXED, width: 100)
            }
            alt
          }
          title
          slug {
            current
          }
        }
      }
    }
    development: allSanityMicrosite(
      filter: { slug: { current: { ne: null } } }
      sort: { fields: [slug___current], order: ASC }
    ) {
      edges {
        node {
          id
          mainBannerImage {
            asset {
              _id
              gatsbyImageData(layout: FIXED, width: 100)
            }
            alt
          }
          title
          slug {
            current
          }
        }
      }
    }
  }
`

const MicrositesPage = (props) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const environment = process.env.NODE_ENV
  var micrositeNodes = ''
  if (environment === 'production') {
    micrositeNodes =
      data && data.production && mapEdgesToNodes(data.production).filter(filterOutDocsWithoutSlugs)
  } else {
    micrositeNodes =
      data &&
      data.development &&
      mapEdgesToNodes(data.development).filter(filterOutDocsWithoutSlugs)
  }
  return (
    <Layout>
      <SEO title="Microsites" />
      <Container>
        <h1 className="mt-5 ml-10">Microsites</h1>
        {micrositeNodes && micrositeNodes.length > 0 && (
          <MicrositePreviewGrid nodes={micrositeNodes} />
        )}
      </Container>
    </Layout>
  )
}

export default MicrositesPage
