import { Link } from 'gatsby'
import React from 'react'
// import BlockText from './block-text'

function MicrositePreview(props) {
  return (
    <Link to={`/${props.slug.current}/`} className="text-lg font-bold">
      {props.title}
    </Link>
  )
}

export default MicrositePreview
