import { Link, withPrefix } from 'gatsby'
import React from 'react'
import MicrositePreview from './microsite-preview'
// import styles from './microsite-preview-grid.module.css'

function MicrositePreviewGrid (props) {
  return (
    <div className="ml-10 microsite-list">
      {props.title && (
        <h2 className="">
          {props.browseMoreHref ? (
            <Link to={`${withPrefix('microsites/')}${props.browseMoreHref}`}>{props.title}</Link>
            ) : (
            props.title
          )}
        </h2>
      )}
      <ul className="my-6 leading-loose">
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node.id} className='my-2'>
              <MicrositePreview {...node} />
            </li>
          ))}
      </ul>
      {props.browseMoreHref && (
        <div className="">
          <Link to={`${withPrefix('microsites/')}${props.browseMoreHref}`}>Browse more</Link>
        </div>
      )}
    </div>
  )
}

MicrositePreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default MicrositePreviewGrid
